:global(.bg-blob) {
  background-image: url('src/shared/assets/full-blob.png');
  background-position: -10vw;
  background-repeat: no-repeat;
  background-position-y: 0vh;
  background-size: 55vw 100vh;
}
/* 
.logo {
  max-width: 80%;
} */
.title {
  color: black;
}

@media screen and (min-width: 768px) {
  .title {
    color: white;
  }
}